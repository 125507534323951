import {
  bindActionCreators,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { DocumentSchema } from 'entities/document/model'
import moment, { Moment } from 'moment'
import { useRootDispatch, useTypedSelector } from 'shared/hooks/redux'
import { localStorageService } from 'shared/lib/localStorageService'
import { typedObjectEntries } from 'shared/lib/typedObjectEntries'
import { CalendarObjectSchema, OperationSchema } from 'shared/types/common'

const defaultDocsPeriodFilter: { start: null | string; end: null | string } = {
  start: null,
  end: null,
}

type SerializerDocsPeriodFilter = Record<
  keyof typeof defaultDocsPeriodFilter,
  string | null
>
type DeserializerDocsPeriodFilter = Record<
  keyof typeof defaultDocsPeriodFilter,
  Moment | null
>

interface ListData {
  date: string
  items: OperationSchema<DocumentSchema>[]
}

interface DocsFiltersState {
  originalData: CalendarObjectSchema<DocumentSchema> | undefined
  docsStartPeriodDate: string
  docsStatusFilter: string | null
  docsPeriodFilter: typeof defaultDocsPeriodFilter
}

export const serializerPeriodFilter = (value: DeserializerDocsPeriodFilter) =>
  typedObjectEntries(value).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value && value.format('YYYY-MM-DD'),
    }),
    {} as SerializerDocsPeriodFilter
  )

const deserializerPeriodFilter = (value: SerializerDocsPeriodFilter) =>
  typedObjectEntries(value).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: value && moment(value),
    }),
    {} as DeserializerDocsPeriodFilter
  )

const initDocsStartPeriodDate = () => {
  const storage = localStorageService.get('docsStartPeriodDate') as
    | string
    | null
  if (!storage)
    return moment().startOf('month').add(-1, 'month').format('YYYY-MM-DD')
  return storage
}

const initDocsPeriodFilter = () => {
  const storage = localStorageService.get(
    'docsPeriodFilter'
  ) as SerializerDocsPeriodFilter | null
  if (!storage) return defaultDocsPeriodFilter
  return storage
}

export const initialDocsFiltersState: DocsFiltersState = {
  originalData: undefined,
  docsStartPeriodDate: initDocsStartPeriodDate(),
  docsStatusFilter: localStorageService.get('docsStatusFilter') as
    | string
    | null,
  docsPeriodFilter: initDocsPeriodFilter(),
}

export const slice = createSlice({
  name: 'document-slice',
  initialState: initialDocsFiltersState,
  reducers: {
    setData(
      state,
      { payload }: PayloadAction<CalendarObjectSchema<DocumentSchema>>
    ) {
      if (state.originalData) {
        state.originalData = { ...state.originalData, ...payload }
      } else {
        state.originalData = payload
      }
    },
    setDocsStartPeriodDate(
      state,
      {
        payload,
      }: PayloadAction<NonNullable<DocsFiltersState['docsStartPeriodDate']>>
    ) {
      state.docsStartPeriodDate = payload
      localStorageService.set('docsStartPeriodDate', payload)
    },
    setDocsStatusFilter(
      state,
      {
        payload,
      }: PayloadAction<NonNullable<DocsFiltersState['docsStatusFilter']>>
    ) {
      if (state.docsStatusFilter === payload) {
        state.docsStatusFilter = null
        localStorageService.remove('docsStatusFilter')
      } else {
        state.docsStatusFilter = payload
        localStorageService.set('docsStatusFilter', payload)
      }
    },
    setDocsPeriodFilter(
      state,
      { payload }: PayloadAction<DocsFiltersState['docsPeriodFilter']>
    ) {
      state.docsPeriodFilter = payload
      localStorageService.set('docsPeriodFilter', JSON.stringify(payload))
    },
  },
  selectors: {
    selectState: createSelector([(state) => state], (state) => ({
      ...state,
      docsStartPeriodDate: moment(state.docsStartPeriodDate),
      docsPeriodFilter: deserializerPeriodFilter(state.docsPeriodFilter),
    })),
    selectFilteredDocs: createSelector(
      [(state) => state],
      (state: DocsFiltersState) => {
        const { originalData, docsPeriodFilter, docsStatusFilter } = state
        const { start, end } = docsPeriodFilter

        const startMoment = start ? moment(start) : undefined
        const endMoment = end ? moment(end) : undefined

        if (originalData) {
          const docsByPeriod = Object.entries(originalData)
            .reduce((result, [date, dateData]) => {
              if (
                ((!startMoment &&
                  !endMoment &&
                  moment().isSameOrBefore(date, 'day')) ||
                  (!endMoment && startMoment?.isSame(date, 'day')) ||
                  (startMoment?.isSameOrBefore(date, 'day') &&
                    endMoment?.isSameOrAfter(date, 'day'))) &&
                dateData.some(({ items }) => items.length)
              ) {
                return [...result, { date, items: dateData }]
              }
              return result
            }, [] as ListData[])
            .sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            )

          if (docsStatusFilter) {
            const filteredDocs = docsByPeriod.reduce((result, data) => {
              const newItems = data.items.filter(
                ({ name, items }) => docsStatusFilter === name && items.length
              )
              if (newItems.length)
                return [
                  ...result,
                  {
                    ...data,
                    items: newItems,
                  },
                ]
              return result
            }, [] as ListData[])

            return filteredDocs
          } else return docsByPeriod
        }
        return []
      }
    ),
  },
})

const { selectState, selectFilteredDocs } = slice.selectors

export const useDocsState = () =>
  useTypedSelector<
    Omit<DocsFiltersState, 'docsPeriodFilter' | 'docsStartPeriodDate'> & {
      docsPeriodFilter: DeserializerDocsPeriodFilter
      docsStartPeriodDate: Moment
    }
  >(selectState)

export const useFilteredDocs = () =>
  useTypedSelector<ListData[]>(selectFilteredDocs)

export const useFiltersActions = () => {
  const dispatch = useRootDispatch()
  return bindActionCreators(slice.actions, dispatch)
}

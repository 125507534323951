import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from 'shared/api/customFetchBaseQuery'
import { SERVER_URL } from 'shared/configs/serverConfig'
import { AccountSchema } from './account.types'

export const api = createApi({
  reducerPath: 'account-api',
  baseQuery: customBaseQuery({ baseUrl: `${SERVER_URL}/accounts` }),
  endpoints: (builder) => ({
    accounts: builder.query<AccountSchema[], null>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
    }),
  }),
})

export const { useAccountsQuery } = api

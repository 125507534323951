import { useMemo, useRef } from 'react'
import { Stack, Box, Typography, Switch, Skeleton } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Grid from '@mui/material/Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import moment, { Moment } from 'moment'
import { documentModel } from 'entities/document'
import { Calendar } from 'widgets/Calendar'
import { ToggleOperationFilter } from 'shared/ui/ToggleOperationFilter/ToggleOperationFilter'
import { getVariantByOperationName } from 'shared/lib/getOperationVariantByName'
import { grey } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles'
import { DocumentSchema } from 'entities/document/model'
import {
  OperationList,
  OperationListProps,
} from 'shared/ui/OperationList/OperationList'
import { PageProps, PaletteVariant } from 'shared/types/common'
import { generatePath, useNavigate } from 'react-router'
import { useSwipeable } from 'react-swipeable'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { formatAmount } from 'shared/lib/formatAmount'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { useApproveDocumentContext } from 'features/document/approve'

const DocsSkeleton = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element
}) => {
  if (!visible) return children
  return (
    <Stack spacing={1} px={2} pt={1}>
      <Box pb={1}>
        <Skeleton variant="rounded" height={32} width={124} />
      </Box>
      <Skeleton variant="rounded" height={37} />
      <Skeleton variant="rounded" height={56} />
      <Skeleton variant="rounded" height={56} />
    </Stack>
  )
}

export const MainPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()

  const {
    originalData,
    docsStatusFilter,
    docsPeriodFilter,
    docsStartPeriodDate,
  } = documentModel.useDocsState()

  const { setDocsStatusFilter, setDocsPeriodFilter, setDocsStartPeriodDate } =
    documentModel.useFiltersActions()

  const filteredDocs = documentModel.useFilteredDocs()

  const startDateQuery = useMemo(() => {
    if (
      docsPeriodFilter.start &&
      docsPeriodFilter.start.diff(
        docsStartPeriodDate.clone().add(1, 'month'),
        'month'
      ) < 0
    ) {
      return docsPeriodFilter.start.format('YYYY-MM-DD')
    }
    return docsStartPeriodDate.format('YYYY-MM-DD')
  }, [docsPeriodFilter, docsStartPeriodDate])

  const endDateQuery = useMemo(() => {
    if (
      docsPeriodFilter.end &&
      docsPeriodFilter.end.diff(
        docsStartPeriodDate.clone().add(1, 'month'),
        'month'
      ) >= 2
    ) {
      return docsPeriodFilter.end.format('YYYY-MM-DD')
    }
    return docsStartPeriodDate
      .clone()
      .add(2, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')
  }, [docsPeriodFilter, docsStartPeriodDate])

  const { isLoading, isFetching, isError } =
    documentModel.useDocumentsByPeriodQuery(
      {
        startDate: startDateQuery,
        endDate: endDateQuery,
      },
      { skip: !isOnline, refetchOnMountOrArgChange: true, refetchOnFocus: true }
    )

  const [
    approveAction,
    { isLoading: isApproving, isSuccess: approved, reset },
  ] = useApproveDocumentContext()

  const { palette } = useTheme()

  const dateHeaderRef = useRef<HTMLDivElement>(null)

  const approving = isApproving || (!isLoading && isFetching && approved)

  const operationFilterOnChange = (newValue: string) => {
    setDocsStatusFilter(newValue)
  }

  const getDayDotsVariants = (day: Moment) => {
    const dayInfo = originalData?.[day.format('YYYY-MM-DD')]
    return dayInfo
      ?.filter(({ name }) => !docsStatusFilter || docsStatusFilter === name)
      .reduce(
        (result, { name, items }) =>
          items.length ? [...result, getVariantByOperationName(name)] : result,
        [] as PaletteVariant[]
      )
  }

  const dateOnChange = (targetDay: Moment) => {
    if (docsPeriodFilter.start && !docsPeriodFilter.end) {
      if (targetDay.isAfter(docsPeriodFilter.start, 'day')) {
        setDocsPeriodFilter(
          documentModel.serializerPeriodFilter({
            ...docsPeriodFilter,
            end: targetDay,
          })
        )
      } else if (targetDay.isSame(docsPeriodFilter.start, 'day'))
        setDocsPeriodFilter({ start: null, end: null })
      else
        setDocsPeriodFilter(
          documentModel.serializerPeriodFilter({
            start: targetDay,
            end: null,
          })
        )
    } else
      setDocsPeriodFilter(
        documentModel.serializerPeriodFilter({ start: targetDay, end: null })
      )
  }

  const onMonthChange = (targetMonth: Moment) => {
    if (
      targetMonth.diff(moment().endOf('month'), 'month') > -4 &&
      targetMonth.diff(moment().startOf('month'), 'month') < 4
    ) {
      setDocsStartPeriodDate(
        targetMonth.clone().add(-1, 'month').format('YYYY-MM-DD')
      )
      reset()
    }
  }

  const onApprove = async ({ guid, confirmed }: DocumentSchema) => {
    reset()
    await approveAction({ guid, confirmed: confirmed ? 0 : 1 })
  }

  const onDocClick: OperationListProps<DocumentSchema>['onRowClick'] = ({
    id,
  }) => {
    navigate(
      generatePath(RoutePath.document, {
        documentid: id,
      })
    )
  }

  const onSwipedLeft = () => {
    if (
      docsStartPeriodDate.clone().diff(moment().startOf('month'), 'month') < 1
    ) {
      setDocsStartPeriodDate(
        docsStartPeriodDate.clone().add(1, 'month').format('YYYY-MM-DD')
      )
    }
  }

  const onSwipedRight = () => {
    if (
      docsStartPeriodDate.clone().diff(moment().endOf('month'), 'month') > -3
    ) {
      setDocsStartPeriodDate(
        docsStartPeriodDate.clone().add(-1, 'month').format('YYYY-MM-DD')
      )
    }
  }

  const shouldDisableMonth = (date: Moment) => {
    if (date.diff(moment().endOf('month'), 'month') < -2) return true
    if (date.diff(moment().startOf('month'), 'month') > 2) return true
    return false
  }

  const { ref: swipeRef } = useSwipeable({ onSwipedLeft, onSwipedRight })

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={approving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout
        header={
          <Stack spacing={1} pb={1} borderBottom={`1px solid ${grey[300]}`}>
            <Typography variant="h4" fontWeight="bold" px={2}>
              Главная
            </Typography>
            <ToggleOperationFilter
              options={['Разрешено', 'Не подтверждено']}
              getOptionValue={(option) => option}
              getLabel={(option) => option}
              isSelected={(option) => docsStatusFilter === option}
              onChange={operationFilterOnChange}
            />
          </Stack>
        }
      >
        {
          <Box
            flexDirection="column"
            display="flex"
            flexGrow={1}
            ref={swipeRef}
          >
            <Box my={2}>
              <Calendar
                value={docsStartPeriodDate.clone().add(1, 'month')}
                period={docsPeriodFilter}
                loading={isLoading}
                disabled={isError}
                onChange={dateOnChange}
                onMonthChange={onMonthChange}
                getDayDotsVariants={getDayDotsVariants}
                shouldDisableMonth={shouldDisableMonth}
              />
            </Box>
            <DocsSkeleton visible={isLoading}>
              <>
                {filteredDocs.length ? (
                  filteredDocs.map((doc) => (
                    <Box
                      key={doc.date}
                      px={2}
                      py={1}
                      borderTop={`1px solid ${grey[300]}`}
                    >
                      <Box
                        position="sticky"
                        top={0}
                        bgcolor="white"
                        zIndex={20}
                        pb={1}
                        ref={dateHeaderRef}
                      >
                        <Typography variant="h5" color={palette.primary.main}>
                          {moment().isSame(doc.date, 'day')
                            ? 'Сегодня'
                            : moment(doc.date).format('D MMMM')}
                        </Typography>
                      </Box>
                      {doc.items.map(({ name, items }) =>
                        items.length ? (
                          <OperationList
                            key={name}
                            header={name}
                            data={items}
                            getId={(data) => data.guid}
                            getTitle={(data) =>
                              data.recipient || data.manager || 'Без получателя'
                            }
                            getDescription={(data) =>
                              formatAmount(data.sum / 100, {
                                currency: 'RUB',
                                withZero: true,
                              })
                            }
                            getType={(data) => data?.financial_org || ''}
                            variant={getVariantByOperationName(name)}
                            top={dateHeaderRef.current?.offsetHeight}
                            onRowClick={onDocClick}
                            renderAddContent={(data) => (
                              <>
                                <Grid
                                  size={12}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="end"
                                >
                                  <Switch
                                    checked={!!data.confirmed}
                                    disabled={!data.actual || !isOnline}
                                    onChange={() => onApprove(data)}
                                  />
                                </Grid>
                                {!data.actual && (
                                  <Grid size={12} textAlign="center">
                                    <Typography fontSize={12} color="info">
                                      ДС выданы
                                    </Typography>
                                  </Grid>
                                )}
                              </>
                            )}
                          />
                        ) : null
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    flexGrow={1}
                    flexDirection="column"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderTop={`1px solid ${grey[300]}`}
                  >
                    <Typography fontSize={16} color="textSecondary">
                      нет документов
                    </Typography>
                  </Box>
                )}
              </>
            </DocsSkeleton>
          </Box>
        }
      </PageLayout>
    </>
  )
}

import { Box, Typography, BoxProps } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTheme } from '@mui/material/styles'
import { PaletteVariant } from 'shared/types/common'

export type OperationListProps<Data> = {
  header: string
  data: Array<Data>
  variant: PaletteVariant
  getId: (data: Data) => string | number
  getTitle: (data: Data) => string
  getDescription: (data: Data) => string
  top?: BoxProps['top']
  getType?: (data: Data) => string
  onRowClick?: (data: Data) => void
  renderAddContent?: (data: Data) => JSX.Element
}

export function OperationList<Data>({
  header,
  data,
  variant,
  top,
  getId,
  getTitle,
  getDescription,
  getType,
  renderAddContent,
  onRowClick,
}: OperationListProps<Data>) {
  const { palette } = useTheme()
  return (
    <Box bgcolor="white">
      <Box
        px={1}
        py={1}
        bgcolor={palette[variant].light}
        borderRadius={3}
        position="sticky"
        top={top}
        zIndex={10}
      >
        <Typography
          fontSize={14}
          fontWeight="bold"
          color={palette[variant].main}
        >
          {header}
        </Typography>
      </Box>
      {data.map((item) => {
        const id = getId(item)
        const title = getTitle(item)
        const description = getDescription(item)
        const type = getType ? getType(item) : ''
        return (
          <Grid container px={'10px'} key={id} py={0.5}>
            <Grid
              size={9}
              onClick={(event) => {
                onRowClick?.(item)
              }}
            >
              <Grid size={12}>
                <Typography variant="h6" fontWeight="500">
                  {title}
                </Typography>
              </Grid>
              <Grid container size={12}>
                <Grid size={6.5}>
                  <Typography>{description}</Typography>
                </Grid>
                <Grid size={5.5} color={palette.secondary.main}>
                  <Typography>{type}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {renderAddContent && (
              <Grid container size={3}>
                {renderAddContent(item)}
              </Grid>
            )}
          </Grid>
        )
      })}
    </Box>
  )
}

import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { Stack, Typography } from '@mui/material'

interface NotificationProps {
  open: boolean
  message: string
  onClose(): void
}

export const Notification = ({ open, message, onClose }: NotificationProps) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return
    }

    onClose()
  }

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={3000}
      onClose={handleClose}
      message={
        <Stack gap={1} direction="row" alignItems="end">
          <DoneIcon />
          <Typography lineHeight="22px">{message}</Typography>
        </Stack>
      }
      action={action}
    />
  )
}

import { Grid2, Typography, TextField, Box, Link } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { skipToken } from '@reduxjs/toolkit/query'
import { useState, ChangeEvent, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { userModel } from 'entities/user'
import moment from 'moment'
import { isFetchBaseQueryError } from 'shared/lib/predicates'
import { localStorageService } from 'shared/lib/localStorageService'
import { PageProps } from 'shared/types/common'

export const VerifyPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()

  const phone = localStorageService.get('userPhone') as string | null

  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')

  const [refreshCodeTime, setRefreshCodeTime] = useState<number | null>(null)
  const refreshCodeInterval = useRef<ReturnType<typeof setInterval>>()

  const [refreshCode] = userModel.useSigInMutation()

  const [
    verify,
    {
      isLoading: isLoadingVerify,
      error: verifyError,
      data: user,
      isSuccess: isSuccessVerify,
    },
  ] = userModel.useVerifyMutation()

  const { data: verifyInfo } = userModel.useVerifyInfoQuery(
    phone ?? skipToken,
    {
      skip: isLoadingVerify || isSuccessVerify || !isOnline,
    }
  )

  useEffect(() => {
    if (!phone) navigate(RoutePath.login, { replace: true })
    if (user) {
      localStorageService.set('userToken', user.token)
      const initLocationHref = localStorageService.get('initLocationHref')
      if (initLocationHref) {
        navigate((initLocationHref as string).split('#')[1], { replace: true })
        localStorageService.remove('initLocationHref')
      } else navigate(RoutePath.main, { replace: true })
    }
    if (isFetchBaseQueryError(verifyError))
      setCodeError((verifyError.data as { message: string }).message)
  }, [phone, user, verifyError, navigate])

  useEffect(() => {
    if (verifyInfo) {
      const startDate = moment(verifyInfo.date)

      const diff = moment().diff(startDate, 's')

      if (diff < 30) {
        setRefreshCodeTime(30 - diff)
        refreshCodeInterval.current = setInterval(() => {
          const time = 30 - moment().diff(startDate, 's')
          setRefreshCodeTime(time)
          if (!time) {
            clearInterval(refreshCodeInterval.current)
            setRefreshCodeTime(null)
          }
        }, 1000)
      }
    }
    return () => {
      clearInterval(refreshCodeInterval.current)
    }
  }, [verifyInfo])

  const codeOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const pattern = /\d/
    if (!value || pattern.test(value)) setCode(value)
    if (value.length === 6) setCodeError('')
  }

  const toChangePhone = () => navigate(RoutePath.login, { replace: true })

  const onLogin = async () => {
    if (code.length === 6) {
      await verify({ phone, code })
    } else {
      setCodeError('Код подтверждения указан неполностью')
    }
  }

  const onRefreshCode = async () => {
    setCode('')
    setCodeError('')
    await refreshCode({ phone })
  }

  return (
    <Grid2 container gap={15} direction="row" px="5vw" pt="15vh">
      <Grid2 container gap={7} direction="row">
        <Grid2 container gap={6} direction="row">
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h4" fontWeight="bold">
              Подтверждение
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="subtitle1">
              Код подтверждения отправлен на номер <br />
              {!!phone &&
                `+7 ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(
                  7,
                  9
                )} ${phone.slice(9)}`}
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <TextField
            value={code}
            fullWidth
            variant="standard"
            size="medium"
            autoComplete="off"
            disabled={isLoadingVerify || !isOnline}
            slotProps={{
              htmlInput: {
                maxLength: 6,
                pattern: /\d/,
                inputMode: 'numeric',
              },
              input: {},
            }}
            sx={{
              '& .MuiInput-input': { fontSize: 24, fontWeight: 'bold' },
            }}
            slots={{}}
            onChange={codeOnChange}
          />
          <Box height="24px">
            <Typography color="error" lineHeight="24px">
              {codeError}
            </Typography>
          </Box>
          <Grid2 size={{ xs: 12 }}>
            <Link
              component="button"
              variant="button"
              color={
                refreshCodeTime || !verifyInfo || isLoadingVerify
                  ? 'textDisabled'
                  : 'primary'
              }
              disabled={
                !!refreshCodeTime || !verifyInfo || isLoadingVerify || !isOnline
              }
              sx={{ fontSize: 16 }}
              onClick={onRefreshCode}
            >
              Запросить код повторно{' '}
              {refreshCodeTime &&
                `00:${refreshCodeTime < 10 ? '0' : ''}${refreshCodeTime}`}
            </Link>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={{ xs: 12 }} rowGap={12} container>
        <Grid2 size={{ xs: 12 }} container justifyContent="center">
          <LoadingButton
            size="large"
            variant="contained"
            disabled={!isOnline}
            loading={isLoadingVerify}
            sx={{
              width: '75%',
              fontSize: 24,
              fontWeight: 'normal',
              textTransform: 'uppercase',
            }}
            onClick={onLogin}
          >
            Войти
          </LoadingButton>
        </Grid2>
        <Grid2 size={{ xs: 12 }} container justifyContent="center">
          <Link
            component="button"
            variant="button"
            color={isLoadingVerify ? 'textDisabled' : 'primary'}
            disabled={isLoadingVerify}
            sx={{ fontSize: 16 }}
            onClick={toChangePhone}
          >
            Изменить номер телефона
          </Link>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

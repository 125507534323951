import {
  Backdrop,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useNavigate } from 'react-router'
import { settingsModel } from 'entities/settings'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { SettingsSchema } from 'entities/settings/model/settings.types'
import { IntegerField } from 'shared/ui/IntegerField/IntegerField'
import { Notification } from 'shared/ui/Notification/Notification'
import { useRootDispatch } from 'shared/hooks/redux'
import { useState } from 'react'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'
import { PageProps } from 'shared/types/common'

export const SettingsPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()
  const dispatch = useRootDispatch()

  const [openNotify, setOpenNotify] = useState(false)

  const {
    data: settings,
    isLoading,
    isError,
  } = settingsModel.useSettingsQuery(null, {
    skip: !isOnline,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const [saveSettingsAction, { isLoading: saving }] =
    settingsModel.useSaveSettingsMutation()

  const onBack = () => navigate(-1)

  const { control, handleSubmit } = useForm<SettingsSchema>({
    values: settings,
  })

  const saveSettings = async (values: SettingsSchema) => {
    const result = await saveSettingsAction(values)
    if ('data' in result && result.data) {
      setOpenNotify(true)
      dispatch(
        settingsModel.api.util.upsertQueryData('settings', null, result.data)
      )
    }
  }

  const onSave = handleSubmit(saveSettings)

  return (
    <>
      <Notification
        open={openNotify}
        message="Настройки сохранены"
        onClose={() => setOpenNotify(false)}
      />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={saving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageLayout
        withNavbar={false}
        header={
          <Stack px={2}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              onClick={onBack}
            >
              <ArrowBackIosIcon />
              <Typography variant="h4" fontWeight="bold">
                Настройки
              </Typography>
            </Stack>
          </Stack>
        }
      >
        <Stack direction="column" alignItems="center" flexGrow={1}>
          <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            flexGrow={1}
          >
            <Grid container pl={3} pr={5} rowSpacing={2}>
              <Grid size={12}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  sx={{ '& > :not(style)': { m: 1 } }}
                >
                  <FormControl fullWidth>
                    <Controller
                      name="min_doc_sum_limit.vali"
                      control={control}
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            label="Начальная сумма РКО для согласования"
                            disabled={isLoading || isError || !isOnline}
                            helperText="для РКО с меньшей суммой не будет требоваться согласование"
                            slotProps={{
                              htmlInput: {
                                maxLength: 15,
                                pattern: /\d/,
                                inputMode: 'numeric',
                              },
                              input: {
                                inputComponent: IntegerField as any,
                                endAdornment:
                                  isLoading || isError ? undefined : (
                                    <Typography color="textSecondary">
                                      ₽
                                    </Typography>
                                  ),
                              },
                            }}
                          />
                        )
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center">
              <Box>
                <Button
                  size="large"
                  variant="contained"
                  disabled={!isOnline}
                  sx={{
                    fontSize: 24,
                    fontWeight: 'normal',
                    textTransform: 'uppercase',
                  }}
                  onClick={onSave}
                >
                  Сохранить
                </Button>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </PageLayout>
    </>
  )
}

import { Stack } from '@mui/material'
import { ReactNode } from 'react'
import { isIOS, isMobile } from 'react-device-detect'

interface PageLayoutProps {
  withNavbar?: boolean
  header: ReactNode
  children: ReactNode
}
export const PageLayout = ({
  header,
  withNavbar = true,
  children,
}: PageLayoutProps) => {
  const pt = isIOS && isMobile ? '40px' : '16px'
  return (
    <Stack
      pt={pt}
      height={`calc(100% - ${withNavbar ? '60px' : '0px'} - ${pt})`}
    >
      {header}
      <Stack overflow="auto" flexGrow={1}>
        {children}
      </Stack>
    </Stack>
  )
}

import { forwardRef, useMemo } from 'react'
import { NumericFormatProps, NumericFormat } from 'react-number-format'

interface IntegerFieldProps {
  value: number
  onChange: (event: { target: { name: string; value: number | null } }) => void
  name: string
}

export const IntegerField = forwardRef<NumericFormatProps, IntegerFieldProps>(
  function IntegerField(props, ref) {
    const { value, onChange, ...other } = props

    const displayValue = useMemo(() => {
      if (typeof value === 'number') return value / 100
      return ''
    }, [value])

    return (
      <NumericFormat
        {...other}
        value={displayValue}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value:
                typeof values.floatValue === 'number'
                  ? Math.floor(values.floatValue * 100)
                  : null,
            },
          })
        }}
        onBlur={() => {
          if (!value)
            onChange({
              target: {
                name: props.name,
                value: 0,
              },
            })
        }}
        onFocus={() => {
          if (!value)
            onChange({
              target: {
                name: props.name,
                value: null,
              },
            })
        }}
        thousandSeparator=" "
        allowNegative={false}
        decimalScale={0}
      />
    )
  }
)

import { SERVER_URL } from 'shared/configs/serverConfig'
import { customBaseQuery } from 'shared/api/customFetchBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'user',
  tagTypes: ['VERIFY_STATUS'],
  baseQuery: customBaseQuery({ baseUrl: `${SERVER_URL}/users` }),
  endpoints: (builder) => ({
    sigIn: builder.mutation({
      query: (body) => ({
        url: 'auth',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['VERIFY_STATUS'],
    }),

    verifyInfo: builder.query<any, string>({
      query: (phone) => ({
        url: `verify_status/${phone}`,
        method: 'GET',
      }),
      providesTags: ['VERIFY_STATUS'],
    }),

    verify: builder.mutation({
      query: (body) => ({
        url: 'verify',
        method: 'POST',
        body,
      }),
    }),

    user: builder.query<unknown, undefined>({
      query: (body) => ({
        url: 'user',
        method: 'GET',
        body,
      }),
    }),
  }),
})

export const {
  useSigInMutation,
  useVerifyInfoQuery,
  useVerifyMutation,
  useUserQuery,
} = api

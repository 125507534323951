import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Box } from '@mui/system'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { useNavigate } from 'react-router'
import { BlackButton } from 'shared/ui/BlackButton/BlackButton'

export const LogoutPage = () => {
  const navigate = useNavigate()

  const onCancel = () => navigate(-1)

  const onExit = () => {
    localStorage.removeItem('user_token')
    navigate(RoutePath.login, { replace: true })
  }

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Grid container rowSpacing={8} justifyContent="center">
          <Grid width="85%">
            <Typography
              variant="h3"
              align="center"
              sx={{ fontSize: 32, fontWeight: 'medium' }}
            >
              Вы точно хотите выйти из приложения?
            </Typography>
          </Grid>
          <Grid />
          <Grid container columnSpacing={8} justifyContent="center">
            <Grid size={6}>
              <BlackButton
                size="large"
                variant="contained"
                sx={{
                  width: '100%',
                  fontSize: 24,
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={onCancel}
              >
                Отмена
              </BlackButton>
            </Grid>
            <Grid size={6}>
              <Button
                size="large"
                variant="contained"
                sx={{
                  width: '100%',
                  fontSize: 24,
                  fontWeight: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={onExit}
              >
                Выйти
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
};

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
};

#root {
  height: 100%;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 4px; /* ширина для вертикального скролла */
  height: 4px; /* высота для горизонтального скролла */
  background-color: inherit;
}
  
::-webkit-scrollbar-thumb {
  background-color: #d5d4d3;
}
  
::-webkit-scrollbar-thumb:hover {
  background-color: #a2a2a2;
}
  
::-webkit-scrollbar-thumb:active {
  background-color: #707070;
}
  
// стили для FireFox
scrollbar-color: inherit #f0f3f4; /* «цвет ползунка» «цвет полосы скроллбара» */
scrollbar-width: thin; /* толщина */

.MuiBadge-colorSuccess {
  background-color: #18a999;
}

.MuiBadge-colorError {
  background-color: #ee332d;
}
`

import Grid from '@mui/material/Grid2'
import { AccountSchema } from '../model'
import { Typography, Box, Popover, Link } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import moment from 'moment'

import { formatAmount } from 'shared/lib/formatAmount'
import { useState } from 'react'

interface AccountProps {
  data: AccountSchema
}
export const Account = ({ data }: AccountProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Grid container rowSpacing={0} size={12} color="primary">
      <Grid size={!!data.costs ? 8 : 12}>
        <Typography fontSize={18}>{data.name}</Typography>
      </Grid>
      {!!data.costs && (
        <Grid size={4}>
          <Box
            display="flex"
            height="100%"
            alignItems="end"
            justifyContent="end"
          >
            <Link
              component="button"
              variant="subtitle2"
              color="info"
              onClick={onMoreClick}
            >
              <InfoIcon />
            </Link>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box p={2}>
              <Typography fontSize={24}>{data.name}</Typography>
              <Typography
                fontSize="3.75vw"
                color={data.costs ? 'success' : 'primary'}
                display="inline"
              >
                {formatAmount(data.balance / 100, {
                  currency: 'RUB',
                  withZero: true,
                })}
              </Typography>{' '}
              -{' '}
              {data.costsSplit.map(
                ({ id, sum, create_date }, splitIndex, arr) => (
                  <>
                    <Typography
                      key={id}
                      fontSize="3.75vw"
                      color="error"
                      display="inline"
                    >
                      {formatAmount(sum / 100, {
                        currency: 'RUB',
                        withZero: true,
                      })}
                      ({moment(create_date).format('DD.MM')})
                    </Typography>
                    {splitIndex !== arr.length - 1 && <> - </>}
                  </>
                )
              )}{' '}
              ={' '}
              <Typography fontSize="3.75vw" color="primary" display="inline">
                {formatAmount((data.balance - data.costs) / 100, {
                  currency: 'RUB',
                  withZero: true,
                })}
              </Typography>
            </Box>
          </Popover>
        </Grid>
      )}
      <Grid size={12}>
        <Typography
          fontSize="3.75vw"
          color={data.costs ? 'success' : 'primary'}
          display="inline"
        >
          {formatAmount(data.balance / 100, {
            currency: 'RUB',
            withZero: true,
          })}
        </Typography>
        {!!data.costs && (
          <>
            {' '}
            -{' '}
            <Typography fontSize="3.75vw" color="error" display="inline">
              {formatAmount(data.costs / 100, {
                currency: 'RUB',
                withZero: true,
              })}
            </Typography>{' '}
            ={' '}
            <Typography fontSize="3.75vw" color="primary" display="inline">
              {formatAmount((data.balance - data.costs) / 100, {
                currency: 'RUB',
                withZero: true,
              })}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}

import { Stack, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Offline } from 'react-detect-offline'
import { isIOS, isMobile } from 'react-device-detect'

export const OfflineNotify = () => {
  const top = isIOS && isMobile ? '44px' : '20px'
  return (
    <Offline>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        py={0.5}
        px={1.2}
        sx={{
          position: 'fixed',
          top,
          right: 8,
          bgcolor: 'warning.main',
          borderRadius: 12,
          fontSize: 16,
        }}
      >
        <ErrorOutlineIcon color="info" />
        <Typography color="info">Нет доступа к сети</Typography>
      </Stack>
    </Offline>
  )
}

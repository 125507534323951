import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from 'shared/api/customFetchBaseQuery'
import { SERVER_URL } from 'shared/configs/serverConfig'
import { SettingsSchema } from './settings.types'

export const api = createApi({
  reducerPath: 'settings-api',
  tagTypes: ['SETTINGS'],
  baseQuery: customBaseQuery({ baseUrl: `${SERVER_URL}/settings` }),
  endpoints: (builder) => ({
    settings: builder.query<SettingsSchema, null>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
    }),

    saveSettings: builder.mutation<SettingsSchema, SettingsSchema>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { useSettingsQuery, useSaveSettingsMutation } = api

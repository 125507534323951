import {
  Box,
  Grid2,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { userModel } from 'entities/user'
import { isFetchBaseQueryError } from 'shared/lib/predicates'
import { localStorageService } from 'shared/lib/localStorageService'
import { PageProps } from 'shared/types/common'

export const LoginPage = ({ isOnline }: PageProps) => {
  const navigate = useNavigate()

  const [phone, setPhone] = useState('7')
  const [phoneError, setPhoneError] = useState('')

  const [signIn, { isLoading, isSuccess, error }] = userModel.useSigInMutation()

  useEffect(() => {
    if (isSuccess) navigate(RoutePath.verify, { replace: true })
    if (isFetchBaseQueryError(error))
      setPhoneError((error.data as { message: string }).message)
  }, [isSuccess, error, navigate])

  useLayoutEffect(() => {
    const localPhone = localStorageService.get('userPhone') as string | null
    if (localPhone) {
      setPhone(localPhone)
    }
  }, [setPhone])

  const phoneOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value.length === 10) {
      setPhoneError('')
    }
    setPhone(`7${value}`)
  }

  const onContinue = async () => {
    const isValidPhone = phone.length === 11
    if (isValidPhone) {
      setPhoneError('')
      localStorageService.set('userPhone', phone)
      await signIn({ phone })
    } else {
      setPhoneError('Номер телефона указан неполностью')
    }
  }

  return (
    <Grid2 container gap={15} direction="row" px="5vw" pt="15vh">
      <Grid2 size={{ xs: 12 }}>
        <Typography variant="h4" fontWeight="bold">
          Введите <br /> номер телефона
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <TextField
          value={phone.slice(1)}
          fullWidth
          variant="standard"
          size="medium"
          autoComplete="tel"
          disabled={isLoading || !isOnline}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Typography fontSize={24} fontWeight="bold">
                    +7
                  </Typography>
                </InputAdornment>
              ),
            },
            htmlInput: {
              maxLength: 10,
            },
          }}
          type="tel"
          sx={{
            '& .MuiInput-input': { fontSize: 24, fontWeight: 'bold' },
          }}
          onChange={phoneOnChange}
        />
        <Box height="24px">
          <Typography color="error" lineHeight="24px">
            {phoneError}
          </Typography>
        </Box>
      </Grid2>

      <Grid2 size={{ xs: 12 }} container justifyContent="center">
        <LoadingButton
          size="large"
          variant="contained"
          sx={{
            width: '75%',
            fontSize: 24,
            fontWeight: 'normal',
            textTransform: 'uppercase',
          }}
          disabled={!isOnline}
          loading={isLoading}
          onClick={onContinue}
        >
          Продолжить
        </LoadingButton>
      </Grid2>
    </Grid2>
  )
}

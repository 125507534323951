import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4FACFE',
      light: '#EFF7FF',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#7B5BBE',
      light: '#EBE8F0',
    },
    error: {
      main: '#EE332D',
      light: '#FFE5E4',
    },
    warning: {
      main: '#FECD4F',
      light: '#FFF6E0',
    },
    info: {
      main: '#6C6C6C',
      light: '#F3F3F3',
    },
    success: {
      main: '#18A999',
      light: '#DBF8F4',
    },
  },
})

import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { localStorageService } from 'shared/lib/localStorageService'

export const customBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers, { endpoint }) => {
        if (
          endpoint !== 'sigIn' &&
          endpoint !== 'verifyInfo' &&
          endpoint !== 'verify'
        ) {
          const token = localStorageService.get('userToken') as string | null
          // if (!token) {
          //   localStorageService.set('initLocationHref', window.location.href)
          //   window.location.hash = `#${RoutePath.login}`
          // }
          headers.set('authorization', `Bearer ${token}`)
        }
        return headers
      },
    })
    const result = await baseQuery(args, api, extraOptions)

    if (result.error && result.error.status === 401) {
      localStorageService.set('initLocationHref', window.location.href)
      window.location.hash = `#${RoutePath.login}`
    }

    return result.data
      ? { data: result.data as unknown }
      : {
          error: {
            ...result.error,
            data: result.error?.data || { message: 'Произошла ошибка' },
          } as FetchBaseQueryError,
        }
  }

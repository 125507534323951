import {
  Stack,
  Typography,
  Box,
  Skeleton,
  Tabs,
  Tab,
  TabsProps,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Grid from '@mui/material/Grid2'
import { Account, accountModel } from 'entities/account'
import { useEffect, useMemo, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { PageProps } from 'shared/types/common'
import { PageLayout } from 'shared/ui/PageLayout/PageLayout'

const AccountSkeleton = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element
}) => {
  if (!visible) return children
  return (
    <Stack spacing={1.5} px={2} py={2}>
      {Array.from({ length: 7 }).map((_, index) => (
        <Stack spacing={1}>
          <Skeleton variant="rounded" height={22} width={325} />
          <Skeleton variant="rounded" height={20} width={220} />
        </Stack>
      ))}
    </Stack>
  )
}

const TabsSkeleton = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element | null
}) => {
  if (!visible) return children
  return <Skeleton variant="rounded" height={48} />
}

export const AccountsPage = ({ isOnline }: PageProps) => {
  const { data, isLoading } = accountModel.useAccountsQuery(null, {
    skip: !isOnline,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })

  const [tabIndex, setTabIndex] = useState<number | null>(null)

  const accountTypes = useMemo(() => {
    const list = new Set<string>()
    if (data) {
      data.forEach(({ type }) => {
        if (type) {
          list.add(type)
        }
      })
    }

    return [...list.values()]
  }, [data])

  const displayAccounts = useMemo(() => {
    if (data && typeof tabIndex === 'number')
      return data.filter(({ type }) => type === accountTypes[tabIndex])
    return []
  }, [data, accountTypes, tabIndex])

  useEffect(() => {
    if (accountTypes.length && !tabIndex) {
      setTabIndex(0)
    }
  }, [accountTypes, tabIndex])

  const onTabChange: TabsProps['onChange'] = (event, value) => {
    console.log('change')
    setTabIndex(value)
  }

  const onSwipedLeft = () => {
    setTabIndex((prevTabIndex) => {
      if (
        typeof prevTabIndex === 'number' &&
        prevTabIndex !== accountTypes.length - 1
      )
        return prevTabIndex + 1
      return prevTabIndex
    })
  }

  const onSwipedRight = () => {
    setTabIndex((prevTabIndex) => {
      if (prevTabIndex) return prevTabIndex - 1
      return prevTabIndex
    })
  }

  const { ref: swipeRef } = useSwipeable({ onSwipedLeft, onSwipedRight })

  return (
    <PageLayout
      header={
        <Stack
          spacing={1}
          mb={1}
          borderBottom={
            !!accountTypes.length ? `1px solid ${grey[300]}` : 'none'
          }
        >
          <Typography variant="h4" fontWeight="bold" px={2}>
            Счета
          </Typography>
          <TabsSkeleton visible={isLoading}>
            {!!accountTypes.length ? (
              <Tabs
                value={tabIndex}
                variant={accountTypes.length > 3 ? 'scrollable' : 'fullWidth'}
                scrollButtons
                allowScrollButtonsMobile
                onChange={onTabChange}
                sx={{
                  '.MuiTabs-scrollButtons.Mui-disabled': {
                    opacity: 0.3,
                  },
                }}
              >
                {accountTypes.map((type, tabIndex) => (
                  <Tab
                    key={tabIndex}
                    value={tabIndex}
                    label={type}
                    wrapped
                    sx={{
                      padding: '16px 2px',
                    }}
                  />
                ))}
              </Tabs>
            ) : null}
          </TabsSkeleton>
        </Stack>
      }
    >
      <AccountSkeleton visible={isLoading}>
        <Box ref={swipeRef} display="flex" flexDirection="column" flexGrow={1}>
          {accountTypes.length ? (
            <Grid container px={2} py={1} rowSpacing={1.5}>
              {displayAccounts.map((item) => (
                <Account key={item.id} data={item} />
              ))}
            </Grid>
          ) : (
            <Box
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography fontSize={16} color="textSecondary">
                нет счетов
              </Typography>
            </Box>
          )}
        </Box>
      </AccountSkeleton>
    </PageLayout>
  )
}

import { Link, Stack, Typography } from '@mui/material'
import { RoutePath } from 'app/config/routeConfig/routeConfig'
import { useNavigate } from 'react-router'

export const NotFoundPage = () => {
  const navigate = useNavigate()

  const toMain = () => navigate(RoutePath.main)

  return (
    <Stack gap={2} height="100vh" alignItems="center" justifyContent="center">
      <Typography variant="h4">Страница не найдена</Typography>
      <Link
        component="button"
        variant="body1"
        color={'primary'}
        sx={{ fontSize: 24 }}
        onClick={toMain}
      >
        На главную
      </Link>
    </Stack>
  )
}

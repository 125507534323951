import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Stack } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers'
import { Moment } from 'moment'
import { PaletteVariant } from 'shared/types/common'

const CalendarSkeleton = styled(DayCalendarSkeleton)`
  .MuiDayCalendarSkeleton-daySkeleton {
    border-radius: 50%;
    height: 42px !important;
    width: 42px !important;
    margin: 1.5px;
  }
`

const CustomDateCalendar = styled(DateCalendar)`
  .MuiDayCalendarSkeleton-daySkeleton {
    border-radius: 50%;
    height: 42px !important;
    width: 42px !important;
    margin: 1.5px;
  }
`

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  isSelected: boolean
  isHovered: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(
  ({ isSelected, isHovered, day, outsideCurrentMonth }) => ({
    borderRadius: '50%',
    fontSize: 16,
    ...(isSelected && {
      backgroundColor: 'rgba(79,172,254, 0.41)',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(79,172,254, 0.41)',
      },
    }),
    ...(isHovered && {
      backgroundColor: blue[50],
      '&:hover, &:focus': {
        backgroundColor: blue[50],
      },
    }),
    ...(!outsideCurrentMonth && !isSelected && { backgroundColor: '#F3F3F3' }),
  })
) as React.ComponentType<CustomPickerDayProps>

type DayProps = PickersDayProps<Moment> & {
  selectedPeriod?: { start: Moment | null; end: Moment | null }
  hoveredDay?: Moment | null
  dotsVariants?: Array<PaletteVariant>
}

const Day = (props: DayProps) => {
  const { day, selectedPeriod, hoveredDay, dotsVariants, ...other } = props
  const { palette } = useTheme()
  return (
    <Box width={45} height={45} position="relative">
      <CustomPickersDay
        {...other}
        day={day}
        sx={{ p: 2.6, m: 0.2 }}
        disableMargin
        selected={false}
        isSelected={
          !selectedPeriod?.end
            ? day.isSame(selectedPeriod?.start, 'day')
            : day.isSameOrAfter(selectedPeriod?.start, 'day') &&
              day.isSameOrBefore(selectedPeriod?.end, 'day')
        }
        isHovered={day.isSame(hoveredDay, 'day')}
      />
      {!other.outsideCurrentMonth && dotsVariants && (
        <Stack
          direction="row"
          width="100%"
          justifyContent="center"
          position="absolute"
          bottom={10}
          left={0}
        >
          {dotsVariants.map((variant) => (
            <FiberManualRecordIcon
              key={variant}
              sx={{
                fontSize: 5,
                color: palette[variant].main,
              }}
            />
          ))}
        </Stack>
      )}
    </Box>
  )
}

interface CalendarProps {
  period: { start: Moment | null; end: Moment | null }
  value?: Moment
  minDate?: Moment
  maxDate?: Moment
  referenceDate?: Moment
  loading?: boolean
  disabled?: boolean
  onChange: (day: Moment) => void
  onMonthChange?: (day: Moment) => void
  shouldDisableMonth?: (month: Moment) => boolean
  getDayDotsVariants?: (day: Moment) => Array<PaletteVariant> | undefined
}

export const Calendar = ({
  value,
  minDate,
  maxDate,
  referenceDate,
  period,
  loading,
  disabled,
  onChange,
  onMonthChange,
  shouldDisableMonth,
  getDayDotsVariants,
}: CalendarProps) => {
  const [hoveredDay, setHoveredDay] = useState<Moment | null>(null)

  return (
    <CustomDateCalendar
      value={value}
      referenceDate={referenceDate}
      views={['day']}
      slots={{
        day: Day,
      }}
      slotProps={{
        day: (ownerState) => {
          return {
            selectedPeriod: period,
            hoveredDay,
            dotsVariants: getDayDotsVariants?.(ownerState.day),
            onPointerEnter: () => setHoveredDay(ownerState.day),
            onPointerLeave: () => setHoveredDay(null),
          } as any
        },
      }}
      minDate={minDate}
      maxDate={maxDate}
      shouldDisableMonth={shouldDisableMonth}
      disabled={disabled}
      loading={loading}
      renderLoading={() => <CalendarSkeleton />}
      onChange={onChange}
      onMonthChange={onMonthChange}
      showDaysOutsideCurrentMonth
      reduceAnimations
      sx={{
        boxShadow: `0px 0px 5px ${grey[400]}`,
        borderRadius: 3,
        height: 'auto',
        maxHeight: 380,
        '[role=rowgroup]': { position: 'relative' },
        '.MuiDayCalendar-weekDayLabel': {
          width: '100%',
          margin: 0,
        },
      }}
    />
  )
}
